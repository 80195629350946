import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import {
  CreateCategoryMetadata,
  GalleryCategoryView,
  UpdateGalleryCategoryDTO,
} from "./model";

const GALLERY_PREFIX = `${environment.backendUrl}gallery/`;

@Injectable({
  providedIn: "root",
})
export class GalleryRestService {
  constructor(private readonly http: HttpClient) {}

  addNewGallery(gallery: CreateCategoryMetadata) {
    return this.http.post<GalleryCategoryView>(`${GALLERY_PREFIX}v2`, gallery);
  }

  updateGallery(gallery: UpdateGalleryCategoryDTO) {
    return this.http.put<GalleryCategoryView>(
      `${GALLERY_PREFIX}v2/${gallery.category.id}`,
      gallery
    );
  }

  addImageToGallery(formData: FormData) {
    return this.http.post<GalleryCategoryView>(
      `${GALLERY_PREFIX}image`,
      formData
    );
  }

  addImageToGalleryEventTracking(formData: FormData) {
    return this.http.post<GalleryCategoryView>(
      `${GALLERY_PREFIX}image`,
      formData,
      { reportProgress: true, observe: "events" }
    );
  }

  listAllGalleries() {
    return this.http.get<GalleryCategoryView[]>(`${GALLERY_PREFIX}`);
  }

  findGalleryByGalleryId(galleryId: number) {
    return this.http.get<GalleryCategoryView>(`${GALLERY_PREFIX}${galleryId}`);
  }

  findGalleryBySeasonId(seasonId: number) {
    return this.http.get<GalleryCategoryView[]>(
      `${GALLERY_PREFIX}seasonid/${seasonId}`
    );
  }

  listAllGalleriesWithHidden() {
    return this.http.get<GalleryCategoryView[]>(`${GALLERY_PREFIX}hidden`);
  }

  changeGalleryVisibility(galleryId: number, visibility: boolean) {
    return this.http.put<GalleryCategoryView>(
      `${GALLERY_PREFIX}v2/visibility/gallery/${galleryId}?visibility=${visibility}`,
      null
    );
  }

  changeGalleryOrder(galleryId: number, order: number) {
    return this.http.put<GalleryCategoryView[]>(
      `${GALLERY_PREFIX}v2/order/gallery/${galleryId}?order=${order}`,
      null
    );
  }

  /*
  changeImageVisibility(imageId: number, visibility: boolean) {
    return this.http.put<GalleryImage>(`${GALLERY_PREFIX}visibility/image/${imageId}?visibility=${visibility}`, null)
    .pipe(
      tap(response => {
        const galleries = this.galleriesWithHidden.value;
        galleries.forEach(gallery => gallery.images.forEach(image => {
          if (image.id === imageId) {
            image.visible = response.visible;
          }
        }));
      })
    )
  }

  changeImageOrder(image: GalleryImage, order: number) {
    return this.http.put<GalleryImage[]>(`${GALLERY_PREFIX}order/image/${image.id}?order=${order}`, null)
    .pipe(
      tap(response => {
        const galleries = this.galleriesWithHidden.value;
        galleries.forEach(gallery => {
          if (gallery.id === image.categoryId) {
            gallery.images.forEach(imagesInGallery => {
              for (let edited of response) {
                if (imagesInGallery.id === edited.id) {
                  imagesInGallery.order = edited.order;
                }
              }
            });
            gallery.images = gallery.images.sort((a, b) => a.order - b.order);
          }
        });
      })
    )
  }
  */
}
