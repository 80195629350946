import { createActionGroup, props } from "@ngrx/store";
import { GalleryCategoryView } from "../model";

export enum GalleryActions {
  GALLERY_CATEGORIES_FETCH_ALL = "Gallery Categories Fetch All",
  GALLERY_CATEGORIES_FETCH_ALL_SUCCESS = "Gallery Categories Fetch All Success",
  GALLERY_CATEGORY_FETCH_BY_ID = "Gallery Category Fetch By Id",
  GALLERY_CATEGORY_FETCH_BY_ID_SUCCESS = "Gallery category fetch by id success",
  GALLERY_FETCH_BY_SEASON_ID = "Gallery Fetch By Season Id",
  GALLERY_FETCH_BY_SEASON_ID_SUCCESS = "Gallery Fetch By Season Id Success",
  GALLERY_FETCH_BY_SEASON_ID_FAILURE = "Gallery Fetch By Season Id Failure",
  GALLERY_CATEGORY_UPDATE = "Gallery Category Update",
  GALLERY_CATEGORY_CHANGE_VISIBILITY = "Gallery category change visibility",
  GALLERY_CATEGORY_CHANGE_ORDER = "Gallery category change order",
}

export const GalleryApiActions = createActionGroup({
  source: "Gallery API",
  events: {
    [GalleryActions.GALLERY_CATEGORIES_FETCH_ALL]: props<{ param?: unknown }>(),
    [GalleryActions.GALLERY_CATEGORIES_FETCH_ALL_SUCCESS]: props<{
      galleries: GalleryCategoryView[];
    }>(),
    [GalleryActions.GALLERY_CATEGORY_UPDATE]: props<{
      gallery: GalleryCategoryView;
    }>(),
    [GalleryActions.GALLERY_CATEGORY_FETCH_BY_ID]: props<{ id: number }>(),
    [GalleryActions.GALLERY_CATEGORY_FETCH_BY_ID_SUCCESS]: props<{
      gallery: GalleryCategoryView;
    }>(),
    [GalleryActions.GALLERY_FETCH_BY_SEASON_ID]: props<{ seasonId: number }>(),
    [GalleryActions.GALLERY_FETCH_BY_SEASON_ID_SUCCESS]: props<{
      galleries: GalleryCategoryView[];
    }>(),
    [GalleryActions.GALLERY_FETCH_BY_SEASON_ID_FAILURE]: props<{
      empty?: unknown;
    }>(),
    [GalleryActions.GALLERY_CATEGORY_CHANGE_VISIBILITY]: props<{
      galleryId: number;
      visibility: boolean;
    }>(),
    [GalleryActions.GALLERY_CATEGORY_CHANGE_ORDER]: props<{
      galleryId: number;
      order: number;
    }>(),
  },
});
